<template>
  <div class="card">
    <a-button
      type="dashed"
      size="large"
      icon="book"
      @click="showModal"
      style="margin-bottom: 20px"
      >{{ $t("all.add") }}</a-button
    >
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      width="50%"
      :height="1200"
      :footer="false"
    >
      <a-form :form="form" @submit="addClass">
        <a-form-item :label="$t('etablissement.nom')">
          <a-input
            :placeholder="$t('etablissement.nom')"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('all.requiredName'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="$t('etablissement.niveau')">
          <a-select
            v-decorator="[
              'level',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.niveau'),
                  },
                ],
              },
            ]"
            :placeholder="$t('choisir.niveauClass')"
          >
            <a-select-option
              v-for="(value, name, index) in levelSelectData[schoolType]"
              :key="index"
              :value="name"
              >{{ value }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('etablissement.fraisInscri')">
          <a-input
            type="number"
            step="0.5"
            v-decorator="[
              'inscription',
              {
                rules: [
                  {
                    required: false,
                    min: 0,
                    message: $t('requis.fraisInscri'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-divider>Frais mensuelle</a-divider>

        <a-form-item :label="$t('etablissement.fraisMensuel')">
          <a-input
            type="number"
            step="0.5"
            button-style="solid"
            v-decorator="[
              'monthly',
              {
                rules: [
                  {
                    required: true,
                    min: 0,
                    message: $t('requis.fraisMensuel'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-divider>Frais des tranches</a-divider>
        <a-form-item>
          <a-row v-for="(item, index) in tranchesList" :key="index">
            <a-col :span="3"> Tranche {{ item.trancheNumber }} : </a-col>
            <a-col :span="11">
              <a-select
                v-model="item.months"
                mode="multiple"
                style="width: 100%"
                placeholder="Mois"
              >
                <a-select-option
                  v-for="i in monthRangesList"
                  :key="i"
                  :value="i"
                >
                  {{ moment(i).format("MMMM") }}
                </a-select-option>
              </a-select></a-col
            >
            <a-col :span="8">
              <a-input-number
                style="width: 100%"
                class="ml-2"
                :value="item.amount"
                v-model="item.amount"
                placeholder="Montant"
            /></a-col>
            <a-col :span="2">
              <a-button
                type="dashed"
                class="ml-4"
                shape="circle"
                icon="delete"
                @click="
                  () => {
                    tranchesList.splice(index, 1);
                  }
                "
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8"></a-col>
            <a-col :span="14">
              <a-button
                type="primary"
                class="mr-3 m-auto"
                @click="
                  () => {
                    tranchesList.push({
                      trancheNumber: tranchesList.length + 1,
                      amount: undefined,
                      months: [],
                    });
                  }
                "
              >
                Ajouter une tranche
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
            >{{ $t("action.ajouter") }}</a-button
          >
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingClasse"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template
        v-for="col in ['name', 'schoolarYear', 'createdAt']"
        :slot="col"
        slot-scope="text, record"
      >
        <div v-if="!['createdAt', 'schoolarYear'].includes(col)" :key="col">
          <template>{{ text }}</template>
        </div>
        <div v-else :key="col" style="text-align: center">
          <template>{{ text }}</template>
        </div>
      </template>
      <template slot="level" slot-scope="text, record">
        <div style="text-align: center">
          {{ levelLabes[Number(text) + 2] }}
        </div>
      </template>
      <template slot="tranches" slot-scope="text">
        <li v-for="t in text" :key="t.trancheNumber">
          {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
        </li>
      </template>
      <span slot="operation" slot-scope="text, record">
        <a-button type="primary" @click="() => edit(record)">
          <a-icon type="edit" />
          {{ $t("action.modifier") }}
        </a-button>
      </span>
      <span slot="operationSupprimer" slot-scope="text, record">
        <a-button type="danger">
          <a-popconfirm
            :title="$t('requis.supp')"
            @confirm="() => supp(record._id)"
          >
            <a>{{ $t("action.supprimer") }}</a>
          </a-popconfirm>
        </a-button>
      </span>
    </a-table>
    <!-- UPDATE DRAWER -->
    <a-drawer
      v-if="updateDrawer"
      :title="$t('action.modifier') + ' ' + selectedClassRoom.name"
      :width="720"
      :visible="updateDrawer"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose('update')"
    >
      <a-form :form="updateForm" :key="formKey">
        <a-form-item :label="$t('etablissement.nom')">
          <a-input
            :placeholder="$t('etablissement.nom')"
            v-decorator="[
              'name',
              {
                initialValue: selectedClassRoom.name,
                rules: [
                  {
                    required: true,
                    message: $t('all.requiredName'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="$t('etablissement.niveau')">
          <a-select
            v-decorator="[
              'level',
              {
                initialValue: selectedClassRoom.level.toString(),
                rules: [
                  {
                    required: true,
                    message: $t('requis.niveau'),
                  },
                ],
              },
            ]"
            :placeholder="$t('choisir.niveauClass')"
          >
            <a-select-option
              v-for="(value, name) in levelSelectData[schoolType]"
              :key="name"
              :value="name"
              >{{ value }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('etablissement.fraisInscri')">
          <a-input
            type="number"
            step="0.5"
            v-decorator="[
              'inscription',
              {
                initialValue: selectedClassRoom.inscription.toString(),
                rules: [
                  {
                    required: true,
                    min: 0,
                    message: $t('requis.fraisInscri'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-divider>Frais mensuelle</a-divider>
        <a-form-item :label="$t('etablissement.fraisMensuel')">
          <a-input
            type="number"
            step="0.5"
            button-style="solid"
            v-decorator="[
              'monthly',
              {
                initialValue: selectedClassRoom.monthly.toString(),
                rules: [
                  {
                    required: true,
                    min: 0,
                    message: $t('requis.fraisMensuel'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-divider>Frais des tranches</a-divider>
        <a-form-item>
          <a-row v-for="(item, index) in selectedTranches" :key="index">
            <a-col :span="3"> Tranche {{ item.trancheNumber }} : </a-col>
            <a-col :span="11">
              <a-select
                v-model="item.months"
                mode="multiple"
                style="width: 100%"
                placeholder="Mois"
              >
                <a-select-option
                  v-for="i in monthRangesList"
                  :key="i"
                  :value="i"
                >
                  {{ moment(i).format("MMMM") }}
                </a-select-option>
              </a-select></a-col
            >
            <a-col :span="8">
              <a-input-number
                style="width: 100%"
                class="ml-2"
                :value="item.amount"
                v-model="item.amount"
                placeholder="Montant"
            /></a-col>
            <a-col :span="2">
              <a-button
                type="dashed"
                class="ml-4"
                shape="circle"
                icon="delete"
                @click="
                  () => {
                    selectedTranches.splice(index, 1);
                  }
                "
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8"></a-col>
            <a-col :span="14">
              <a-button type="primary" class="mr-3 m-auto" @click="addTranche">
                Ajouter une tranche
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose('update')">{{
          $t("action.annuler")
        }}</a-button>
        <a-button
          type="primary"
          @click="save"
          :loading="loadingClasse"
          :disabled="loadingClasse"
          >{{ $t("action.modifier") }}</a-button
        >
      </div>
    </a-drawer>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import { getRangeMonthsSY } from "@/utilities/settings";

var classesData = [];
export default {
  name: "classes",
  computed: mapState(["settings", "user"]),
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "classForm" });
    this.updateForm = this.$form.createForm(this, {
      name: "updateClassForm",
    });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Classes",
    });

    this.getClassRooms();
    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;
    this.monthRangesList = getRangeMonthsSY(
      this.settings.activeSchoolarYear,
      this.settings.schoolarYears
    );
    console.log(this.monthRangesList);
  },
  data() {
    return {
      moment,
      schoolType: null,
      photo: null,
      fileList2: [],
      fileList1: [],
      fileList3: [],
      tranchesList: [],
      monthRangesList: [],
      selectedClassRoom: {},
      selectedTranches: [],
      formKey: 0,
      updateDrawer: false,
      columns: [
        {
          title: this.$t("etablissement.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.niveau"),
          dataIndex: "level",
          key: "level",
          width: "150px",
          scopedSlots: { customRender: "level" },
          sorter: (a, b) => a.level - b.level,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.fraisInscri"),
          dataIndex: "inscription",
          key: "inscription",
          scopedSlots: { customRender: "inscription" },
          sorter: (a, b) => a.inscription - b.inscription,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.fraisMensuel"),
          dataIndex: "monthly",
          key: "monthly",
          scopedSlots: { customRender: "monthly" },
          sorter: (a, b) => a.monthly - b.monthly,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Tranches",
          dataIndex: "tranches",
          key: "tranches",
          scopedSlots: { customRender: "tranches" },
        },
        {
          title: this.$t("etablissement.anneeScolaire"),
          dataIndex: "schoolarYear",
          key: "schoolarYear",
          scopedSlots: { customRender: "schoolarYear" },
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.dateCreation"),
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: { customRender: "createdAt" },
        },
        {
          title: this.$t("all.edit"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      levelLabes: [
        this.$t("niveau.niveauPre"),
        this.$t("niveau.niveau0"),
        "",
        this.$t("niveau.niveau1"),
        this.$t("niveau.niveau2"),
        this.$t("niveau.niveau3"),
        this.$t("niveau.niveau4"),
        this.$t("niveau.niveau5"),
        this.$t("niveau.niveau6"),
        this.$t("niveau.niveau7"),
        this.$t("niveau.niveau8"),
        this.$t("niveau.niveau9"),
        this.$t("niveau.niveau10"),
        this.$t("niveau.niveau11"),
        this.$t("niveau.niveau12"),
        this.$t("niveau.niveau13"),
      ],
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      updateForm: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
    };
  },
  methods: {
    addTranche() {
      this.selectedTranches.push({
        trancheNumber: this.selectedTranches.length + 1,
        amount: undefined,
        months: [],
      });
    },
    async getClassRooms() {
      await apiClient
        .post("/classrooms/filter", {
          query: {
            schoolarYear: this.settings.activeSchoolarYear,
            status: "active",
          },
          aggregation: {
            $project: {
              __v: 0,
              updatedAt: 0,
              teachers: 0,
            },
          },
        })
        .then((res) => {
          classesData = [...res.data];
          classesData = classesData.map((elem) => {
            var sc = "";
            try {
              sc = this.settings.schoolarYears.filter(
                (s) => s._id == elem.schoolarYear
              )[0].name;
            } catch {
              sc = "-";
            }
            return {
              ...elem,
              key: elem._id,
              createdAt: moment(elem.createdAt).format("DD-MM-YYYY"),
              schoolarYear: sc,
            };
          });
          this.data = classesData;
          this.cacheData = classesData.map((item) => ({ ...item }));
          this.tableLeading = false;
        })
        .catch((e) => this.$message.error(this.$t("error.aucClasse")));
    },

    onClose(type) {
      this.updateDrawer = false;
    },

    addClass(e) {
      e.preventDefault();
      console.log(this.tranchesList);
      const thereEmpty = this.tranchesList.filter(
        (s) => !s.trancheNumber || !s.amount
      );

      if (thereEmpty.length > 0) {
        this.$message.warning("Vérifier les frais des tranches");
        return;
      }
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;
          values.level = Number(values.level);
          values.tranches = this.tranchesList;
          apiClient
            .put("/classrooms", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.classeAjout"));
              res.data.key = res.data._id;
              this.data.push(this.prepareClaasRoom(res.data));
              this.cacheData.push(this.prepareClaasRoom(res.data));
              this.handleCancel();
            })
            .catch((e) => {
              console.error(e);
              this.$message.error(this.$t("error.impoAjoutClasse"));
            })
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    prepareClaasRoom(classroom) {
      var sc = "";
      try {
        sc = this.settings.schoolarYears.filter(
          (s) => s._id == classroom.schoolarYear
        )[0].name;
      } catch {
        sc = "-";
      }
      return {
        ...classroom,
        key: classroom._id,
        createdAt: moment(classroom.createdAt).format("DD-MM-YYYY"),
        schoolarYear: sc,
      };
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();

      this.visibleModal = false;
    },

    edit(record) {
      this.selectedClassRoom = { ...record };
      if (!this.selectedClassRoom.tranches) this.selectedTranches = [];
      else this.selectedTranches = this.selectedClassRoom.tranches;

      this.formKey += 1;
      this.updateDrawer = true;
    },
    save(e) {
      e.preventDefault();

      this.loadingClasse = true;
      this.updateForm.validateFieldsAndScroll((err, values) => {
        console.log(values);
        if (!err) {
          const updates = {
            name: values.name,
            inscription: values.inscription,
            monthly: values.monthly,
            level: values.level,
            tranches: this.selectedTranches,
          };
          apiClient
            .patch("/classrooms/" + this.selectedClassRoom._id, {
              data: updates,
            })
            .then(() => {
              this.$message.success(this.$t("success.classeMSA"));
              this.updateDrawer = false;
              this.getClassRooms();
            })
            .catch((e) => {
              console.error(e);
              this.$message.error(this.$t("error.modif"));
            })
            .finally(() => (this.loadingClasse = false));
        } else {
          console.log(err);
          this.loadingClasse = false;
        }
      });
    },

    supp(key) {
      apiClient
        .patch("/classrooms/" + key, { data: { status: "inactive" } })
        .then(() => {
          this.$message.success(this.$t("success.classeSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.classeNonSupp")));
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
>
