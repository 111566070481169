<template>
  <div class="card">
    <a-button
      type="dashed"
      size="large"
      icon="book"
      @click="showModal"
      style="margin-bottom: 20px"
      >{{ $t("action.ajouter") }}</a-button
    >
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :width="800"
      :height="1200"
      :footer="false"
    >
      <a-form :form="form" class="container" @submit="addActivity">
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.designation')">
              <a-input
                :placeholder="$t('etablissement.designation')"
                v-decorator="[
                  'designation',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.designation'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.ordrePriorite')">
              <a-input
                :placeholder="$t('etablissement.ordrePriorite')"
                v-decorator="[
                  'order',
                  {
                    rules: [
                      {
                        required: false,
                        message: $t('requis.order'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.service')">
              <a-select
                show-search
                :autoFocus="true"
                option-filter-prop="children"
                :filter-option="filterOption"
                :placeholder="$t('etablissement.service')"
                v-decorator="[
                  'service',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.service'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  :value="item._id"
                  v-for="item in activeServicesData"
                  :key="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.responsable')">
              <a-input
                :placeholder="$t('etablissement.responsableActivite')"
                v-decorator="['reponsible']"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.fraisInscri')">
              <a-input
                type="number"
                step="0.5"
                :placeholder="$t('etablissement.fraisInscri')"
                v-decorator="[
                  `inscription`,
                  {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-2">
            <a-form-item :label="$t('etablissement.seuleFois')">
              <a-switch
                :checked="once"
                @change="trigerOnce"
                v-decorator="[`once`]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-4">
            <a-form-item :label="$t('etablissement.fraisMensuel')">
              <a-input
                type="number"
                step="0.5"
                :disabled="once"
                :placeholder="$t('etablissement.fraisMensuel')"
                v-decorator="[
                  `monthly`,
                  {
                    rules: [
                      {
                        required: !once,
                        message: $t('requis.fraisMensuel'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <a-divider>Frais des tranches</a-divider>
            <a-form-item>
              <a-row v-for="(item, index) in tranchesList" :key="index">
                <a-col :span="3"> Tranche {{ item.trancheNumber }} : </a-col>
                <a-col :span="11">
                  <a-select
                    v-model="item.months"
                    mode="multiple"
                    style="width: 100%"
                    placeholder="Mois"
                  >
                    <a-select-option
                      v-for="i in monthRangesList"
                      :key="i"
                      :value="i"
                    >
                      {{ moment(i).format("MMMM") }}
                    </a-select-option>
                  </a-select></a-col
                >
                <a-col :span="8">
                  <a-input-number
                    style="width: 100%"
                    class="ml-2"
                    :value="item.amount"
                    v-model="item.amount"
                    placeholder="Montant"
                /></a-col>
                <a-col :span="2">
                  <a-button
                    type="dashed"
                    class="ml-4"
                    shape="circle"
                    icon="delete"
                    @click="
                      () => {
                        tranchesList.splice(index, 1);
                      }
                    "
                  />
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8"></a-col>
                <a-col :span="14">
                  <a-button
                    type="primary"
                    class="mr-3 m-auto"
                    @click="
                      () => {
                        tranchesList.push({
                          trancheNumber: tranchesList.length + 1,
                          amount: undefined,
                          months: [],
                        });
                      }
                    "
                  >
                    Ajouter une tranche
                  </a-button>
                </a-col>
              </a-row>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.dateDebut')">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'start',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('all.requiredStartDate'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.dateFin')">
              <a-date-picker
                :disabled="once"
                style="width: 100%"
                v-decorator="[
                  'end',
                  {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
            >{{ $t("action.ajouter") }}</a-button
          >
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingClasse"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template slot="service" slot-scope="text, record">
        <a-select
          show-search
          :disabled="!record.editable"
          :autoFocus="true"
          style="width: 100%"
          :placeholder="$t('recette.fournisseur')"
          option-filter-prop="children"
          :filter-option="filterOption"
          @change="(e) => handleChange(e, record._id, 'service')"
          :default-value="record.service ? record.service : ''"
        >
          <a-select-option
            v-for="service in activeServicesData"
            :value="service._id"
            :key="service._id"
            >{{ service.name }}</a-select-option
          >
        </a-select>
      </template>

      <template
        v-for="col in ['designation', 'start', 'end', 'order', 'reponsible']"
        :slot="col"
        slot-scope="text, record"
      >
        <div v-if="!['end', 'start'].includes(col)" :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :type="
              col == ['inscription', 'monthly'].includes(col)
                ? 'number'
                : 'text'
            "
            :value="text"
            @change="(e) => handleChange(e.target.value, record._id, col)"
          />
          <template v-else>
            {{
              col == "monthly"
                ? !record.once
                  ? text
                  : $t("etablissement.seuleFois")
                : text
            }}
          </template>
        </div>
        <div v-else :key="col">
          <a-date-picker
            v-if="record.editable"
            :value="moment(text)"
            @change="(v) => handleChange(v, record._id, col)"
          />
          <template v-else>
            <div>{{ moment(text).format("DD-MM-YYYY") }}</div>
          </template>
        </div>
      </template>
      <template slot="tranches" slot-scope="text">
        <li v-for="t in text" :key="t.trancheNumber">
          {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
        </li>
      </template>
      <span slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <a-button
              size="small"
              type="primary"
              @click="() => save(record._id)"
              >{{ $t("action.enregistrer") }}</a-button
            >
            <a-button size="small" type="danger">
              <a-popconfirm
                :title="$t('all.sureToCancelAlert')"
                @confirm="() => cancel(record._id)"
              >
                <a>{{ $t("action.annuler") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
          <span v-else>
            <a-button
              type="primary"
              :disabled="editingKey !== ''"
              @click="() => edit(record._id)"
            >
              <a-icon type="edit" />
              {{ $t("action.modifier") }}
            </a-button>
          </span>
        </div>
      </span>
      <span slot="operationSupprimer" slot-scope="text, record">
        <div class="editable-row-operations">
          <span>
            <a-button :disabled="editingKey !== ''" type="danger">
              <a-popconfirm
                :title="$t('requis.supp')"
                @confirm="() => supp(record._id)"
              >
                <a>{{ $t("action.supprimer") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
        </div>
      </span>
    </a-table>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import { getRangeMonthsSY } from "@/utilities/settings";

var activitiessData = [];
export default {
  name: "activities",
  computed: mapState(["settings"]),
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "classForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Activités",
    });
    this.monthRangesList = getRangeMonthsSY(
      this.settings.activeSchoolarYear,
      this.settings.schoolarYears
    );
    await apiClient
      .post("/services/filter", { query: {} })
      .then((res) => {
        this.services = res.data;
        this.activeServicesData = res.data.filter((e) => e.status == "active");
      })
      .catch((e) => {
        console.log(e);
        this.$message.error(this.$t("error.aucActivite"));
      });
    await apiClient
      .post("/activities/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            __v: 0,
            updatedAt: 0,
            teachers: 0,
          },
        },
      })
      .then((res) => {
        activitiessData = [...res.data];
        activitiessData = activitiessData.map((elem) => {
          var sc = "";
          try {
            if (
              this.services.find((e) => e._id === elem.service).status ===
              "inactive"
            )
              elem.service = "--";
            sc = this.settings.schoolarYears.filter(
              (s) => s._id == elem.schoolarYear
            )[0]._id;
          } catch {
            sc = "";
          }
          return {
            ...elem,
            key: elem._id,
            schoolarYear: sc,
          };
        });
        this.data = activitiessData;
        this.cacheData = activitiessData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) => this.$message.error(this.$t("error.aucActivite")));
  },
  data() {
    return {
      tranchesList: [],
      monthRangesList: [],
      columns: [
        {
          title: this.$t("etablissement.service"),
          dataIndex: "service",
          key: "service",
          scopedSlots: { customRender: "service" },
          sorter: (a, b) => a.service.length - b.service.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.designation"),
          dataIndex: "designation",
          key: "designation",
          scopedSlots: { customRender: "designation" },
          sorter: (a, b) => a.designation.length - b.designation.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.fraisInscri"),
          dataIndex: "inscription",
          key: "inscription",
          scopedSlots: { customRender: "inscription" },
          sorter: (a, b) => a.inscription - b.inscription,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.fraisMensuel"),
          dataIndex: "monthly",
          key: "monthly",
          scopedSlots: { customRender: "monthly" },
          sorter: (a, b) => a.monthly - b.monthly,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Tranches",
          dataIndex: "tranches",
          key: "tranches",
          scopedSlots: { customRender: "tranches" },
        },
        {
          title: this.$t("etablissement.responsable"),
          dataIndex: "reponsible",
          key: "reponsible",
          scopedSlots: { customRender: "reponsible" },
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.dateDebut"),
          dataIndex: "start",
          key: "start",
          scopedSlots: { customRender: "start" },
        },
        {
          title: this.$t("etablissement.dateFin"),
          dataIndex: "end",
          key: "end",
          scopedSlots: { customRender: "end" },
        },
        {
          title: this.$t("etablissement.order"),
          dataIndex: "order",
          key: "order",
          scopedSlots: { customRender: "order" },
          sorter: (a, b) => a.order.length - b.order.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      services: [],
      activeServicesData: [],
      once: true,
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
    };
  },
  methods: {
    trigerOnce(v) {
      this.once = v;
    },
    moment(any) {
      return moment(any);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addActivity(e) {
      e.preventDefault();
      console.log(this.tranchesList);
      const thereEmpty = this.tranchesList.filter(
        (s) => !s.trancheNumber || !s.amount
      );

      if (thereEmpty.length > 0) {
        this.$message.warning("Vérifier les frais des tranches");
        return;
      }
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;
          values.tranches = this.tranchesList;

          let data = { ...values };
          data.once = this.once;
          if (this.once && values.monthly) delete values.monthly;
          apiClient
            .put("/activities", { data: data })
            .then((res) => {
              this.$message.success(this.$t("success.activiteAjout"));
              res.data.key = res.data._id;
              this.data.push(res.data);
              this.cacheData.push(res.data);
              this.handleCancel();
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoAjoutActivites"))
            )
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates =
        target.monthly > 0
          ? {
              service: target.service,
              designation: target.designation,
              schoolarYear: target.schoolarYear,
              inscription: target.inscription,
              once: false,
              monthly: target.monthly,
              start: target.start,
              end: target.end,
              order: target.order,
              reponsible: target.reponsible,
            }
          : {
              service: target.service,
              schoolarYear: target.schoolarYear,
              designation: target.designation,
              inscription: target.inscription,
              once: true,
              start: target.start,
              end: target.end,
              order: target.order,
              reponsible: target.reponsible,
            };
      if (!target.schoolarYear) delete updates.schoolarYear;
      apiClient
        .patch("/activities/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.activiteMSA"));
        })
        .catch((e) => {
          console.error(e);
          this.$message.error(this.$t("error.activiteNonMSA"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .patch("/activities/" + key, { data: { status: "inactive" } })
        .then(() => {
          this.$message.success(this.$t("success.activiteSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.activiteNonSupp"));
        });
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
